<template>
    <div>
        <div class="blog-articles-section" :class="{ 'empty-page': !blogArticles.length }">
            <div class="posts__wrapper" v-if="blogArticles.length">
                <h2>{{ $t('blogArticles.blog') }}</h2>
                <h5>{{ $t('main.blogSection.subTitle') }}</h5>
                <BlogCard
                    class="card"
                    v-for="blogArticle in blogArticles"
                    :key="blogArticle.id"
                    :id="blogArticle.id"
                    @click="onBlogArticleClick(blogArticle.id)"
                    :title="$filters.getTranslationByKey(blogArticle.title)"
                    :image="blogArticle.pictures[0]?.picturePath.thumbnail"
                    :date="blogArticle.createdAt"
                />
                <Pagination
                    class="pagination"
                    v-if="blogArticles.length"
                    :current-page="meta.currentPage"
                    :page-count="meta.pageCount"
                    :total-count="meta.totalCount"
                    :per-page="meta.perPage"
                    @pageNum="changePage"
                />
            </div>
            <h2 class="text-center" v-else>{{ $t('blogArticles.noBlogArticlesAdded') }}</h2>
        </div>
    </div>
</template>

<script>
    import BlogCard from '@/components/BlogArticles/BlogCard.vue';
    import Pagination from '@/components/Common/Pagination/Pagination.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AllBlogArticles',
        components: { BlogCard, Pagination },
        computed: {
            ...mapGetters('blogArticle', ['blogArticles', 'meta']),
        },
        methods: {
            ...mapActions('blogArticle', ['getAllBlogArticles']),

            onBlogArticleClick(id) {
                this.$router.push({
                    name: 'blogArticle',
                    params: { blog_article_id: id },
                });
            },

            async changePage(pageNum) {
                try {
                    await this.getAllBlogArticles({ page: pageNum });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            try {
                await this.getAllBlogArticles({ countPerPage: 8 });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .blog-articles-section {
        padding-top: 25px;
        padding-bottom: 25px;

        h2 {
            width: 100%;
            margin-bottom: 8px;
        }

        h5 {
            color: $grey-static;
            width: 100%;
            margin-bottom: 20px;
        }

        .card {
            margin-bottom: 10px;
        }

        .pagination {
            width: 100%;
        }
    }

    @include media($md) {
        .posts__wrapper {
            @include row-align-stretch;
            flex-wrap: wrap;

            .card {
                width: 49%;

                margin-right: 2%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($lg) {
        .blog-articles-section {
            h2 {
                font-size: $font-34;
            }

            h5 {
                margin-bottom: 34px;
            }

            .card {
                width: 32%;
                margin-bottom: 20px;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($xl) {
        .blog-articles-section {
            .card {
                width: 23.5%;
                margin-right: 2%;
                margin-bottom: 20px;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 2%;
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
